
#menu{
    height:20vh;
    width:100vw;
    display:grid;
    grid-template-rows: 5vh 15vh;
    place-items:center;
}
nav{
    display:grid;
    grid-template-columns: 1fr 1fr;
}
nav > p, nav > p:visited, nav > p:link{
    padding:0px 2px;
    user-select: none;
    cursor: pointer;
    text-decoration: none !important;
    color:white !important;
}
.menu{
    text-decoration: none !important;
}
.menu:visited{
    text-decoration: none !important;
}
.menu:link, .menu:hover, .menu:link{
    text-decoration: none !important;
}
#title{
    color:white;
    text-align: center;
    font-size: 3.5rem;
    user-select: none;
    
}
  