.App {
  display:grid;
  place-items:center;
  grid-template-rows:20vh 60vh 20vh;
}

#root{
  background:skyblue;
  height:100vh;
  width:100vw;
  display:grid;
  justify-items: center;
}
