
#searchContainer{

    justify-items: center;
    width:100vw;
    height:60vh;
    grid-template-rows:10vh 50vh;
  }
  
  #results{
    display:grid;
    width:60vw;
    height: minmax(0vh,50vh);
    grid-row-gap: 0px;
    grid-template-rows:repeat(10vh,5);
    background:white;
    border-radius: 0px 0px 25px 25px;
    justify-items:flex-start !important;
  }
  
  .result {
    padding:0px;
    margin:0px;
    display:grid;
    
    place-items:center;
    text-align: left;
    user-select: none;
    height:10vh;
    width:100%;
    cursor: pointer;
    transition:350ms ease-in; 
  }
  
  .result:hover{
    background:orange;
  }
  
  #search{
    padding:10px;
    font-size:2rem;
    width:60vw;
  
    height:10vh;
  }
  